import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_BONUS_PROMOTIONS_EXPERIMENTS = createFcmConfig('bonus_promotions_experiments', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				name: { type: 'string' },
				variants: {
					type: 'record',
					shape: {
						type: 'object',
						shape: {
							anchorAmount: { type: 'number' },
							thresholdAmount: { type: 'number' },
							cpmBelowThreshold: { type: 'number' },
							cpmAboveThreshold: { type: 'number' },
						},
					},
				},
			},
		}),
	),
	defaultValue: undefined,
});
