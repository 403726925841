import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';
import { getLocales } from '@change-corgi/config/locales';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'record',
	keys: getLocales(),
	shape: { type: 'boolean', optional: true },
});

export const FCM_FUTURE_SAP_MEDIA_DISCLAIMER = createFcmConfig('future_sap_media_disclaimer', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {} as ReturnType<typeof normalizer>,
});
