/**
 * @deprecated use getValidEmailDomains() instead
 */
export const emailDomains: readonly string[] = [
	'gmail.com',
	'hotmail.com',
	'yahoo.com',
	'mail.ru',
	'aol.com',
	'hotmail.co.uk',
	'yandex.ru',
	'hotmail.fr',
	'live.com',
	'libero.it',
	'yahoo.co.uk',
	'web.de',
	'gmx.de',
	'msn.com',
	'yahoo.es',
	'orange.fr',
	'outlook.com',
	'hotmail.es',
	'hotmail.it',
	'yahoo.fr',
	'comcast.net',
	'googlemail.com',
	'btinternet.com',
	'naver.com',
	'yahoo.com.ar',
	'ymail.com',
	'me.com',
	'live.co.uk',
	'wanadoo.fr',
	'yahoo.it',
	'alice.it',
	'rambler.ru',
	'icloud.com',
	'free.fr',
	't-online.de',
	'yahoo.com.br',
	'yahoo.de',
	'bigpond.com',
	'bk.ru',
	'hotmail.de',
	'sbcglobal.net',
	'live.fr',
	'tiscali.it',
	'yahoo.co.jp',
	'verizon.net',
	'laposte.net',
	'list.ru',
	'sky.com',
	'att.net',
	'sfr.fr',
	'rocketmail.com',
	'windowslive.com',
	'yahoo.ca',
	'gmx.net',
	'yahoo.com.au',
	'inbox.ru',
	'yahoo.co.in',
	'virgilio.it',
	'yahoo.co.id',
	'live.it',
	'yahoo.com.mx',
	'shaw.ca',
	'mac.com',
	'ntlworld.com',
	'live.com.au',
	'cox.net',
	'hotmail.com.ar',
	'bellsouth.net',
	'ya.ru',
	'talktalk.net',
	'wp.pl',
	'rediffmail.com',
	'telefonica.net',
	'live.ca',
	'freenet.de',
	'xtra.co.nz',
	'optusnet.com.au',
	'live.com.ar',
	'mynet.com',
	'neuf.fr',
	'blueyonder.co.uk',
	'rogers.com',
	'aim.com',
	'hotmail.ca',
	'gmx.at',
	'arcor.de',
	'tin.it',
	'tiscali.co.uk',
	'ig.com.br',
	'optonline.net',
	'ezweb.ne.jp',
	'live.de',
	'charter.net',
	'live.com.mx',
	'uol.com.br',
	'earthlink.net',
	'sympatico.ca',
	'telus.net',
	'ono.com',
	'voila.fr',
	'i.softbank.jp',
	'facebook.com',
	'seznam.cz',
	'abv.bg',
	'bol.com.br',
	'o2.pl',
	'live.nl',
	'ukr.net',
	'hotmail.co.th',
	'hanmail.net',
	'bigpond.net.au',
	'yandex.com',
	'tut.by',
	'virginmedia.com',
	'docomo.ne.jp',
	'hotmail.co.nz',
	'email.it',
	'hotmail.com.tr',
	'terra.com.br',
	'yahoo.in',
	'nate.com',
	'yahoo.gr',
	'online.de',
	'outlook.es',
	'yahoo.com.hk',
	'bbox.fr',
	'outlook.fr',
	'bluewin.ch',
	'juno.com',
	'armyspy.com',
	'inwind.it',
	'gmx.com',
	'iinet.net.au',
	'fibertel.com.ar',
	'btopenworld.com',
	'club-internet.fr',
	'opayq.com',
	'eircom.net',
	'yahoo.co.nz',
	'freemail.hu',
	'hotmail.co.jp',
	'fastwebnet.it',
	'interia.pl',
	'yahoo.ie',
	'telenet.be',
	'y7mail.com',
	'nifty.com',
	'virgin.net',
	'roadrunner.com',
	'aliceadsl.fr',
	'videotron.ca',
	'live.se',
	'aol.co.uk',
	'skynet.be',
	'fsmail.net',
	'op.pl',
	'yahoo.com.ph',
	'windstream.net',
	'yahoo.com.tw',
	'tpg.com.au',
	'wanadoo.es',
	'yahoo.com.sg',
	'live.cl',
	'numericable.fr',
	'cogeco.ca',
	'yahoo.com.tr',
	'teletu.it',
	'westnet.com.au',
	'proxymail.facebook.com',
	'frontier.com',
	'gmail.ru',
	'xtec.cat',
	'embarqmail.com',
	'terra.com',
	'talk21.com',
	'aon.at',
	'clear.net.nz',
	'softbank.ne.jp',
	'prodigy.net.mx',
	'yandex.ua',
	'bell.net',
	'gmx.ch',
	'internode.on.net',
	'live.no',
	'globo.com',
	'hotmail.com.au',
	'btconnect.com',
	'arnet.com.ar',
	'cegetel.net',
	'live.ie',
	'terra.es',
	'live.be',
	'istruzione.it',
	'q.com',
	'hotmail.be',
	'yahoo.com.vn',
	'bigpond.com.au',
	'eastlink.ca',
	'walla.com',
	'iprimus.com.au',
	'ybb.ne.jp',
	'telkomsa.net',
	'hotmail.se',
	'i.ua',
	'gmail.it',
	'inbox.lv',
	'hotmail.com.br',
	'live.dk',
	'katamail.com',
	'citromail.hu',
	'email.com',
	'online.no',
	'netscape.net',
	'hotmail.nl',
	'outlook.de',
	'mweb.co.za',
	'speedy.com.ar',
	'jcom.home.ne.jp',
	'outlook.it',
	'gmx.fr',
	'netzero.net',
	'cfl.rr.com',
	'mindspring.com',
	'suddenlink.net',
];
