import type { Normalizer } from '@change-corgi/config/fcm/normalizers';
import { createJsonNormalizer, normalizeNumber, normalizeString } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const normalizeSsrCacheBuster: Normalizer<string> = (value: unknown) => {
	try {
		return `${normalizeNumber(value)}`;
	} catch (e) {
		return normalizeString(value);
	}
};

export const FCM_CORGI_SSR_CACHE_BUSTER = createFcmConfig('corgi_ssr_cache_buster', {
	normalizer: createJsonNormalizer(normalizeSsrCacheBuster),
	defaultValue: undefined,
});
