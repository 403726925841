import {
	createJsonNormalizer,
	createShapeNormalizer,
	normalizeIsoDatetime,
} from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_TOS_LEGAL_NOTICE_BANNER = createFcmConfig('tos_legal_notice_banner', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				effectiveDate: { type: 'string', normalizer: normalizeIsoDatetime },
				preEffectiveRange: { type: 'number' },
				postEffectiveRange: { type: 'number' },
				maxDisplayCount: { type: 'number' },
			},
		}),
	),
	defaultValue: undefined,
});
