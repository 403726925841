import {
	getLocaleBasedExperimentFromLocale as __getLocaleBasedExperimentFromLocale,
	getLocaleBasedExperimentFromName as __getLocaleBasedExperimentFromName,
	getLocaleBasedExperimentVariantsFromLocale as __getLocaleBasedExperimentVariantsFromLocale,
	getLocaleBasedExperimentVariantsFromName as __getLocaleBasedExperimentVariantsFromName,
} from '@change-corgi/config/fcm/utils';

import type { Normalizer } from './types';

export type { Normalizer };

export const normalizeString: Normalizer<string> = (value: unknown) => {
	if (typeof value !== 'string') {
		throw new Error();
	}
	if (/^\s*$/.exec(value)) {
		throw new Error();
	}
	return value;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeString as any).type = 'string';

export const normalizeBoolean: Normalizer<boolean> = (value: unknown) => {
	if (typeof value !== 'boolean') {
		throw new Error();
	}
	return value;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeBoolean as any).type = 'boolean';

export const normalizeNumber: Normalizer<number> = (value: unknown) => {
	if (typeof value !== 'number') {
		throw new Error();
	}
	return value;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeNumber as any).type = 'number';

export const normalizeRegExp: Normalizer<RegExp> = (value: unknown) => {
	if (value instanceof RegExp) return value;
	const str = normalizeString(value);
	try {
		// eslint-disable-next-line security/detect-non-literal-regexp
		return new RegExp(str);
	} catch (e) {
		throw new Error('Invalid RegExp');
	}
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeRegExp as any).type = 'string';

export const normalizeIsoDatetime: Normalizer<string> = (value: unknown) => {
	const str = normalizeString(value);
	if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[+-]\d{2}:\d{2})$/.test(str)) {
		throw new Error('Invalid ISO Date format');
	}
	if (!new Date(str).getTime()) {
		throw new Error('Invalid ISO Date');
	}
	return str;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeIsoDatetime as any).type = 'string';

export const normalizeJson: Normalizer<unknown> = (value: unknown) => {
	if (typeof value === 'boolean' || typeof value === 'number') {
		return value;
	}
	const str = normalizeString(value);
	try {
		return JSON.parse(str);
	} catch (e) {
		throw new Error(`Invalid JSON value: "${str}"`);
	}
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeJson as any).type = 'json';

export const createJsonNormalizer = <T>(normalizeParsedValue: (parsedValue: unknown) => T): Normalizer<T> => {
	const fn = (value: unknown) => normalizeParsedValue(normalizeJson(value));
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	(fn as any).type = 'json';
	return fn;
};

export const createEnumNormalizer = <T extends string>(values: readonly T[]): Normalizer<T> => {
	const fn = (value: unknown) => {
		const str = normalizeString(value) as T;
		if (!values.includes(str)) {
			throw new Error(`Invalid Enum value: "${str}" not found in ${JSON.stringify(values)}`);
		}
		return str;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	(fn as any).type = 'string';
	return fn;
};

export { createShapeNormalizer } from './shape';
export { createExperimentNormalizer } from './experiment';
export { createLocaleBasedExperimentNormalizer } from './localeBasedExperiment';

/**
 * @deprecated use the one from `@change-corgi/config/fcm/utils`
 */
export const getLocaleBasedExperimentVariantsFromLocale = __getLocaleBasedExperimentVariantsFromLocale;
/**
 * @deprecated use the one from `@change-corgi/config/fcm/utils`
 */
export const getLocaleBasedExperimentVariantsFromName = __getLocaleBasedExperimentVariantsFromName;
/**
 * @deprecated use the one from `@change-corgi/config/fcm/utils`
 */
export const getLocaleBasedExperimentFromLocale = __getLocaleBasedExperimentFromLocale;
/**
 * @deprecated use the one from `@change-corgi/config/fcm/utils`
 */
export const getLocaleBasedExperimentFromName = __getLocaleBasedExperimentFromName;

export const normalizeFullUrl: Normalizer<string> = (value: unknown) => {
	const url = normalizeString(value);
	if (!/^https?:\/\//.test(url)) {
		throw new Error('Invalid URL (protocol missing)');
	}
	return url;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeFullUrl as any).type = 'string';

const YOUTUBE_REGEX =
	/(?:http:|https:)*?\/\/(?:www\.|)(?:youtube\.com|m\.youtube\.com|youtu\.|youtube-nocookie\.com).*(?:v=|v%3D|v\/|(?:a|p)\/(?:a|u)\/\d.*\/|watch\?|vi(?:=|\/)|\/embed\/|oembed\?|be\/|e\/)([^&?%#/\n]*)/;

const YOUTUBE_EMBED_BASE_PATH = 'https://www.youtube.com/embed';

export const normalizeYoutubeEmbedUrl: Normalizer<string> = (value: unknown) => {
	const url = normalizeString(value);
	const youtubeMatch = url.match(YOUTUBE_REGEX);
	if (!youtubeMatch) {
		throw new Error('Invalid YouTube URL');
	}

	const { 1: videoId } = youtubeMatch;
	return `${YOUTUBE_EMBED_BASE_PATH}/${videoId}`;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeYoutubeEmbedUrl as any).type = 'string';

const platformsMap: Record<string, string> = {
	linkedIn: 'https://www.linkedin.com/in',
	facebook: 'https://www.facebook.com',
	twitter: 'https://www.twitter.com',
	instagram: 'https://www.instagram.com',
	twitch: 'https://www.twitch.tv',
	youtube: 'https://www.youtube.com',
	tiktok: 'https://www.tiktok.com',
};

export const normalizeSocialPlatforms: Normalizer<string> = (value: unknown) => {
	const platform = normalizeString(value);
	if (!platformsMap[platform]) {
		throw new Error('Invalid platform name');
	}

	return platformsMap[platform];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(normalizeSocialPlatforms as any).type = 'string';

export type { ObjectShape } from './shape/types';
