import { createJsonNormalizer, createShapeNormalizer, normalizeRegExp } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizeLocationMatch = createShapeNormalizer({
	type: 'array',
	shape: { type: 'string', normalizer: normalizeRegExp },
});

export const FCM_OPTIMIZELY_PAGES = createFcmConfig(
	'optimizely_pages',
	{
		normalizer: createJsonNormalizer(normalizeLocationMatch),
		defaultValue: [] as readonly RegExp[],
	},
	{
		merger: ({ globalValue, countryValue, localeValue }) => {
			const merged = [...globalValue, ...countryValue, ...localeValue];
			return merged.filter((regex, idx) => idx === merged.findIndex((s) => s.toString() === regex.toString()));
		},
	},
);
