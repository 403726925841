import { getCountries } from '@change-corgi/config/countries';
import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_EXPORT_SIGNATURE_BLOCKLIST = createFcmConfig('export_signature_blocklist', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				petitionIds: { type: 'array', shape: { type: 'string' } },
				countryCodeIds: { type: 'array', shape: { type: 'enum', values: getCountries() } },
			},
		}),
	),
	defaultValue: undefined,
});
