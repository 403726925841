import type { CookiePref, Feature } from './types';

type Config = CookiePref | { test: 'ANY' | 'ALL'; prefs: readonly CookiePref[] };

export const MAPPING: Record<Feature, Config> = {
	facebook: 'marketing',
	googleAnalytics: 'analytics',
	googleTagManager: { test: 'ANY', prefs: ['analytics', 'marketing'] },
	optimizely: 'analytics',
};
