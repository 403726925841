import type { Locale } from '@change-corgi/config/locales';

import __instagram from '../config/instagram.json';

type CountryData = Readonly<{
	handle: string;
	localesOverrides?: Readonly<Record<Locale, string>>;
}>;

type InstagramFromJson = Readonly<{
	countries: Readonly<Record<string, CountryData>>;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
export const instagramHandles: InstagramFromJson = __instagram as any;

export function getInstagramHandle({
	locale,
	countryCode,
}: {
	locale: Locale;
	countryCode: string;
}): string | undefined {
	const countryConfig = instagramHandles.countries[countryCode];
	if (!countryConfig) {
		return undefined;
	}
	if (!countryConfig.localesOverrides || !countryConfig.localesOverrides[locale]) {
		return countryConfig.handle;
	}
	return countryConfig.localesOverrides[locale];
}
