import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		countries: {
			type: 'array',
			shape: { type: 'string' },
			optional: true,
		},
		users: {
			type: 'array',
			shape: { type: 'string' },
			optional: true,
		},
	},
});

export const FCM_PRIVACY_ANONYMOUS_USERS = createFcmConfig('privacy_anonymous_users', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {} as ReturnType<typeof normalizer>,
});
