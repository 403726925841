import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
	},
});

export const FCM_PETITION_PROMOTE_PAGE_STATE_COLLECTION = createFcmConfig('petition_promote_page_state_collection', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: { enabled: false } as ReturnType<typeof normalizer>,
});
