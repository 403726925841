import type { ExperimentRecord } from '@change-corgi/config/fcm/utils';

import { createShapeNormalizer } from './shape';
import type { Normalizer } from './types';

function createExperimentNormalizer<V>(variationNormalizer: Normalizer<V>): Normalizer<ExperimentRecord<V>>;
function createExperimentNormalizer<VN extends string, V>(
	variantNames: readonly VN[],
	variationNormalizer: Normalizer<V>,
): Normalizer<ExperimentRecord<V, VN>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
function createExperimentNormalizer(arg1: any, arg2?: any): Normalizer {
	const variationNames = Array.isArray(arg1) ? arg1 : undefined;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const variationNormalizer = Array.isArray(arg1) ? arg2 : arg1;

	return createShapeNormalizer({
		type: 'record',
		shape: {
			type: 'other',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			normalizer: variationNormalizer,
		},
		keys: variationNames,
	});
}

export { createExperimentNormalizer };
