import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_STARTER_SAP_AI_PROMPT = createFcmConfig('starter_sap_ai_prompt', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				legacyAPI: { type: 'boolean', optional: true },
				systemPrompt: { type: 'string' },
				prompt: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							replacer: { type: 'string' },
							filter: { type: 'string' },
							text: { type: 'string' },
						},
					},
				},
				options: {
					type: 'object',
					optional: true,
					shape: {
						model: { type: 'string', optional: true },
						temperature: { type: 'string', optional: true },
						frequency_penalty: { type: 'string', optional: true },
					},
				},
			},
		}),
	),
	defaultValue: {
		isEnabled: false,
		systemPrompt: '',
		prompt: [],
		legacyAPI: undefined,
		options: undefined,
	},
});
