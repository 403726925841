import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const normalizeMobileOptInCountries = createShapeNormalizer({
	type: 'array',
	shape: { type: 'string', normalizer: (country) => country.toLowerCase() },
});

export const FCM_ENGAGEMENT_SIGN_FORM_MOBILE_OPT_IN_CONFIG = createFcmConfig(
	'engagement_sign_form_mobile_opt_in_config',
	{
		normalizer: createJsonNormalizer(normalizeMobileOptInCountries),
		defaultValue: [] as readonly string[],
	},
);
