import type { Environment } from '@change-corgi/config/environments';

import __salesforce from '../config/salesforce.json';

export function getSalesforceHost(env: Environment, purpose: 'violationReporting'): string {
	switch (purpose) {
		case 'violationReporting':
			return __salesforce[env].violationReportingHost;
		default:
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			throw new Error(`Unknown salesforce purpose: ${purpose}`);
	}
}
