import type { Locale } from '@change-corgi/config/locales';

import { helpCountryLegalHelpUrls, helpCountryLegalUrls, helpLocales } from './config';

export function getHelpLocale(locale: Locale): string | undefined {
	return helpLocales[locale];
}

const HELP_BASE = 'https://help.change.org';

export function getHelpUrl(locale: Locale, path?: string): string {
	return [HELP_BASE, getHelpLocale(locale), path].filter((p) => !!p).join('/');
}

export function getLegalUrl(_locale: Locale, countryCodes: readonly string[]): string | undefined {
	const legalUrl = countryCodes.reduce<string | undefined>((acc, code) => acc || helpCountryLegalUrls[code], undefined);
	const legalHelpUrl = countryCodes.reduce<string | undefined>(
		(acc, code) => acc || helpCountryLegalHelpUrls[code],
		undefined,
	);

	if (legalUrl) {
		return legalUrl;
	}

	if (legalHelpUrl) {
		return `${HELP_BASE}${legalHelpUrl}`;
	}

	return undefined;
}
