import { normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_REVENUE_VICTORIOUS_PETITION_PROMOTION_ENABLED = createFcmConfig(
	'revenue_victorious_petition_promotion_enabled',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
);
