import { normalizeNumber } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_COFFEE_RECENT_SUPPORTERS_SIGNATURE_THRESHOLD_BY_COUNTRY = createFcmConfig(
	'coffee_recent_supporters_signature_threshold_by_country',
	{
		normalizer: normalizeNumber,
		defaultValue: 0,
	},
);
