import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

type Method = 'creditCard' | 'paypal' | 'sepa' | 'applePay' | 'googlePay' | 'upi';
const METHODS: readonly Method[] = ['creditCard', 'paypal', 'sepa', 'applePay', 'googlePay', 'upi'];

export const normalizePaymentMethodsEnabledFor = createShapeNormalizer({
	type: 'array',
	shape: {
		type: 'object',
		shape: {
			payment_method_name: { type: 'enum', values: METHODS },
			enabled: { type: 'boolean' },
			safe_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
			block_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
		},
	},
});

export const FCM_PAYMENT_METHODS_ENABLED_FOR = createFcmConfig(
	'payment_methods_enabled_for',
	{
		normalizer: createJsonNormalizer(normalizePaymentMethodsEnabledFor),
		defaultValue: undefined,
	},
	{
		merger: ({ globalValue, countryValue, localeValue }) => {
			// if locale or country value, let's use them but allow global value to override their enabled state if enabled is false in the globalValue
			if (localeValue || countryValue) {
				return (localeValue || countryValue || []).map((value) => ({
					...value,
					enabled:
						(globalValue || []).find((g) => g.payment_method_name === value.payment_method_name && !g.enabled)
							?.enabled ?? value.enabled,
				}));
			}
			return globalValue;
		},
	},
);
