import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		safe_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
		block_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
	},
});

export const FCM_UPI_ENABLED_FOR = createFcmConfig('upi_enabled_for', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: { enabled: false } as ReturnType<typeof normalizer>,
});
