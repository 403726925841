import type { Normalizer } from '@change-corgi/config/fcm/normalizers';
import { createJsonNormalizer, createShapeNormalizer, normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const shapeNormalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		// rolloutExperiment: { type: 'string' },
		// togglePUMA: { type: 'boolean' },
		// hidePUMA: { type: 'boolean' },
	},
});

export const normalizePromotionCtaEnabled: Normalizer<boolean> = (value: unknown) => {
	try {
		return normalizeBoolean(value);
	} catch (e) {
		return shapeNormalizer(value).enabled;
	}
};

export const FCM_CREAM_PETITION_UPDATE_PROMOTION_ASK = createFcmConfig('cream_petition_update_promotion_ask', {
	normalizer: createJsonNormalizer(normalizePromotionCtaEnabled),
	defaultValue: false,
});
