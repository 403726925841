import type { Locale } from '@change-corgi/config/locales';

import __blog from '../config/blog.json';

type CountryData = Readonly<{
	url: string;
	localesOverrides?: Readonly<Record<Locale, string>>;
}>;

type BlogFromJson = Readonly<{
	countries: Readonly<Record<string, CountryData>>;
	fallback: string;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
export const blogUrls: BlogFromJson = __blog as any;
