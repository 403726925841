import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PETITION_SHOW_DISPLAY_CHAT_DAYS_POST_PUBLISH = createFcmConfig(
	'petition_show_display_chat_days_post_publish',
	{
		normalizer: createJsonNormalizer(
			createShapeNormalizer({
				type: 'object',
				shape: {
					start_day: { type: 'number' },
					end_day: { type: 'number' },
				},
			}),
		),
		defaultValue: undefined,
	},
);
