import { normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PROMOTE_FORM_TAX_LANGUAGE_SUPPORT_ENABLED = createFcmConfig(
	'promote_form_tax_language_support_enabled',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
);
