import type { CountryInfo } from '@change-corgi/config/countries';
import { getCountryInfo } from '@change-corgi/config/countries';
import type { Locale } from '@change-corgi/config/locales';

import __legacyCountries from '../../config/legacy/countries.json';

type PartialLegacyCountryInfo = Readonly<{
	locale: Locale;
	lang_extensions?: Partial<Readonly<Record<string, string>>>;
	currency?: string;
	gdpr_enabled: boolean;
	signature_address_granularity?: string;
	regionOverrides?: Readonly<Record<string, Readonly<{ locale: string }>>>;
	surname_before_given_name?: boolean;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
const legacyCountriesJson: Record<string, PartialLegacyCountryInfo> = __legacyCountries as any;

type LegacyCountryInfo = PartialLegacyCountryInfo & CountryInfo;

export const countrySettings = Object.entries(legacyCountriesJson).reduce<Partial<Record<string, LegacyCountryInfo>>>(
	(acc, [code, info]) => {
		const countryInfo = getCountryInfo(code) as CountryInfo;
		const computedInfo = {
			...info,
			surname_before_given_name: !!info.surname_before_given_name,
			...countryInfo,
			gdpr_enabled: countryInfo.gdpr,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		delete (computedInfo as any).__comment__;
		return {
			...acc,
			[code]: computedInfo,
		};
	},
	{},
) as Readonly<Record<string, LegacyCountryInfo>>;
