import type { Locale } from '@change-corgi/config/locales';

import { blogUrls } from './config';

export function getBlogUrl({ locale, countryCode }: { locale: Locale; countryCode: string }): string {
	const countryConfig = blogUrls.countries[countryCode];
	if (!countryConfig) {
		return blogUrls.fallback;
	}
	if (!countryConfig.localesOverrides || !countryConfig.localesOverrides[locale]) {
		return countryConfig.url;
	}
	return countryConfig.localesOverrides[locale];
}
