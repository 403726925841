import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_CAROUSEL_CONFIGURATION = createFcmConfig('carousel_configuration', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				maxPetitionsToShow: { type: 'number' },
				minPetitionsToShow: {
					type: 'object',
					shape: {
						production: { type: 'number' },
						staging: { type: 'number' },
						demo: { type: 'number' },
						development: { type: 'number' },
					},
				},
				promotedInsertionInterval: { type: 'number' },
				userRecommendedModelName: { type: 'string', optional: true },
				cardFetchStrategies: { type: 'array', shape: { type: 'string' } },
			},
		}),
	),
	defaultValue: {
		maxPetitionsToShow: 60,
		minPetitionsToShow: {
			production: 5,
			staging: 5,
			demo: 3,
			development: 3,
		},
		promotedInsertionInterval: 2,
		userRecommendedModelName: '',
		cardFetchStrategies: ['topic_petitions', 'popular_weekly', 'promoted'],
	},
});
