import type { Locale, LocaleInfo } from '@change-corgi/config/locales';
import { getLocaleInfo } from '@change-corgi/config/locales';

import __legacyLocales from '../../config/legacy/locales.json';

type PartialLegacyLocaleInfo = Readonly<{
	facebook: string;
}>;

const legacyLocalesJson = __legacyLocales as Record<Locale, PartialLegacyLocaleInfo>;

type LegacyLocaleInfo<LOC extends Locale = Locale> = PartialLegacyLocaleInfo & LocaleInfo<LOC>;

export const localeMappings = Object.entries(legacyLocalesJson).reduce<Partial<Record<Locale, LegacyLocaleInfo>>>(
	(acc, [locale, info]) => ({
		...acc,
		[locale]: {
			...info,
			...getLocaleInfo(locale as Locale),
		},
	}),
	{},
) as Readonly<Record<Locale, LegacyLocaleInfo>>;
