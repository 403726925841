import type { ExperimentRecord } from './experiment';

type LocaleBasedExperimentDetails<V = unknown, VN extends string = string> = {
	locales: readonly string[];
	variants: ExperimentRecord<V, VN>;
};

export type LocaleBasedExperiment<V = unknown, VN extends string = string> = {
	experiments: Record<string, LocaleBasedExperimentDetails<V, VN>>;
};

type FullLocaleBasedExperimentDetails<V = unknown, VN extends string = string> = LocaleBasedExperimentDetails<V, VN> & {
	name: string;
};

export function getLocaleBasedExperimentFromLocale<VN extends string, V>(
	experiment: LocaleBasedExperiment<V, VN> | undefined,
	locale: string,
): FullLocaleBasedExperimentDetails<V, VN> | undefined {
	if (!experiment) return undefined;
	const entry = Object.entries(experiment.experiments).find(([_name, { locales }]) => locales.includes(locale));
	if (!entry) return undefined;
	const [name, value] = entry;
	return { name, ...value };
}

export function getLocaleBasedExperimentFromName<VN extends string, V>(
	experiment: LocaleBasedExperiment<V, VN> | undefined,
	experimentName: string,
): FullLocaleBasedExperimentDetails<V, VN> | undefined {
	if (!experiment) return undefined;
	const entry = Object.entries(experiment.experiments).find(([name]) => name === experimentName);
	if (!entry) return undefined;
	const [name, value] = entry;
	return { name, ...value };
}

export function getLocaleBasedExperimentVariantsFromLocale<VN extends string, V>(
	experiment: LocaleBasedExperiment<V, VN> | undefined,
	locale: string,
): ExperimentRecord<V, VN> | undefined {
	if (!experiment) return undefined;
	return Object.values(experiment.experiments).find(({ locales }) => locales.includes(locale))?.variants;
}

export function getLocaleBasedExperimentVariantsFromName<VN extends string, V>(
	experiment: LocaleBasedExperiment<V, VN> | undefined,
	experimentName: string,
): ExperimentRecord<V, VN> | undefined {
	if (!experiment) return undefined;
	return Object.entries(experiment.experiments).find(([name]) => name === experimentName)?.[1]?.variants;
}
