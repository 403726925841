import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_HOMEPAGE_NOTICE = createFcmConfig('homepage_notice', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				desktop_prefix: { type: 'string' },
				desktop_copy: { type: 'string' },
				desktop_link_text: { type: 'string' },
				mobile_prefix: { type: 'string' },
				mobile_copy: { type: 'string' },
				mobile_link_text: { type: 'string' },
				url: { type: 'string' },
			},
		}),
	),
	defaultValue: undefined,
});
