import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_TOS_LEGAL_NOTICE_BANNER_COPY = createFcmConfig('tos_legal_notice_banner_copy', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				preEffectiveText: { type: 'string' },
				postEffectiveText: { type: 'string' },
			},
		}),
	),
	defaultValue: undefined,
});
