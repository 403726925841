import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_MOBILE_APP_RELEASES = createFcmConfig('mobile_app_releases', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				upgrade_recommended: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							version: { type: 'string' },
							build: { type: 'string', optional: true },
						},
					},
				},
				upgrade_required: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							version: { type: 'string' },
							build: { type: 'string', optional: true },
						},
					},
				},
			},
		}),
	),
	defaultValue: {
		upgrade_recommended: [],
		upgrade_required: [],
	},
});
