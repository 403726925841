import { assert } from '../assert';

export function assertString(value: unknown, allowOptional: boolean, key?: string): void {
	if (allowOptional && value === undefined) return;
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(typeof value === 'string', `Invalid type for ${key || 'value'}: ${JSON.stringify(value)} (string expected)`);
}

export function assertEnum(value: unknown, values: readonly string[], allowOptional: boolean, key?: string): void {
	if (allowOptional && value === undefined) return;
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(typeof value === 'string', `Invalid type for ${key || 'value'}: ${JSON.stringify(value)} (string expected)`);
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(
		values.includes(value),
		`Invalid enum value for ${key || 'value'}: ${JSON.stringify(value)} (any value from ${JSON.stringify(
			values,
		)} expected)`,
	);
}

export function assertNumber(value: unknown, allowOptional: boolean, key?: string): void {
	if (allowOptional && value === undefined) return;
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(typeof value === 'number', `Invalid type for ${key || 'value'}: ${JSON.stringify(value)} (number expected)`);
}

export function assertBoolean(value: unknown, allowOptional: boolean, key?: string): void {
	if (allowOptional && value === undefined) return;
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(typeof value === 'boolean', `Invalid type for ${key || 'value'}: ${JSON.stringify(value)} (boolean expected)`);
}

export function assertObject(value: unknown, allowOptional: boolean, key?: string): void {
	if (allowOptional && value === undefined) return;
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(typeof value === 'object', `Invalid type for ${key || 'value'}: ${JSON.stringify(value)} (object expected)`);
	assert(!Array.isArray(value), `Invalid array type for ${key || 'value'} (object expected)`);
}

export function assertArray(value: unknown, allowOptional: boolean, key?: string): void {
	if (allowOptional && value === undefined) return;
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(Array.isArray(value), `Invalid type for ${key || 'value'}: ${JSON.stringify(value)} (array expected)`);
}

export function assertRecord(value: unknown, allowOptional: boolean, key?: string): void {
	assertObject(value, allowOptional, key);
}

export function assertOther(value: unknown, allowOptional: boolean, key?: string): void {
	if (allowOptional && value === undefined) return;
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	assert(!!value, `Invalid type for ${key || 'value'}: ${JSON.stringify(value)} (non-undefined value expected)`);
}
