import __locales from '../config/locales.json';

import type { BetaLocale, Locale, NonBetaLocale } from './types';

type PartialLocaleInfo = Readonly<{
	display: string;
	sort: string;
	beta: boolean;
}>;

type LocalesFromJson = Readonly<{
	locales: Readonly<Record<Locale, PartialLocaleInfo>>;
	fallback: NonBetaLocale;
}>;

const localesFromJson = __locales as LocalesFromJson;

export type LocaleInfo<LOC extends Locale = Locale> = PartialLocaleInfo &
	Readonly<{
		locale: LOC;
	}>;

const localeMap = Object.entries(localesFromJson.locales).reduce<Partial<Record<Locale, LocaleInfo<Locale>>>>(
	(acc, [locale, info]) => ({
		...acc,
		[locale]: {
			...info,
			locale: locale as Locale,
		},
	}),
	{},
) as Readonly<Record<Locale, LocaleInfo<Locale>>>;

// eslint-disable-next-line no-nested-ternary
const sortFn = (l1: LocaleInfo, l2: LocaleInfo) => (l1.sort < l2.sort ? -1 : l1.sort > l2.sort ? 1 : 0);

/**
 * Sorted list of all locale objects
 *
 * @deprecated use getLocalesInfo() instead
 */
export const localesInfo: ReadonlyArray<LocaleInfo<Locale>> = Object.values(localeMap).sort(sortFn);
/**
 * Sorted list of beta locale objects
 *
 * @deprecated use getLocalesInfo('beta') instead
 */
export const betaLocalesInfo = localesInfo.filter(({ beta }) => beta) as ReadonlyArray<LocaleInfo<BetaLocale>>;
/**
 * Sorted list of non-beta locale objects
 *
 * @deprecated use getLocalesInfo('nonBeta') instead
 */
export const nonBetaLocalesInfo = localesInfo.filter(({ beta }) => !beta) as ReadonlyArray<LocaleInfo<NonBetaLocale>>;

/**
 * Sorted list of all locales
 *
 * @deprecated use getLocales() instead
 */
export const locales: readonly Locale[] = localesInfo.map(({ locale }) => locale);
/**
 * Sorted list of beta locales
 *
 * @deprecated use getLocales('beta') instead
 */
export const betaLocales: readonly BetaLocale[] = betaLocalesInfo.map(({ locale }) => locale);
/**
 * Sorted list of non-beta locales
 *
 * @deprecated use getLocales('nonBeta') instead
 */
export const nonBetaLocales: readonly NonBetaLocale[] = nonBetaLocalesInfo.map(({ locale }) => locale);

/**
 * Sorted list of locale objects
 */
function getLocalesInfo(filter: 'beta'): ReadonlyArray<LocaleInfo<BetaLocale>>;
function getLocalesInfo(filter: 'nonBeta'): ReadonlyArray<LocaleInfo<NonBetaLocale>>;
function getLocalesInfo(filter?: 'all'): ReadonlyArray<LocaleInfo<Locale>>;
function getLocalesInfo(filter: 'all' | 'beta' | 'nonBeta' = 'all'): ReadonlyArray<LocaleInfo<Locale>> {
	if (filter === 'beta') return betaLocalesInfo;
	if (filter === 'nonBeta') return nonBetaLocalesInfo;
	return localesInfo;
}

/**
 * Sorted list of locales
 */
function getLocales(filter: 'beta'): readonly BetaLocale[];
function getLocales(filter: 'nonBeta'): readonly NonBetaLocale[];
function getLocales(filter?: 'all'): readonly Locale[];
function getLocales(filter: 'all' | 'beta' | 'nonBeta' = 'all'): readonly Locale[] {
	if (filter === 'beta') return betaLocales;
	if (filter === 'nonBeta') return nonBetaLocales;
	return locales;
}

export { getLocalesInfo, getLocales };

export function getLocaleInfo(locale: Locale): LocaleInfo<Locale> {
	return localeMap[locale];
}
export function isBetaLocale(locale: Locale): locale is BetaLocale {
	return !!localeMap[locale]?.beta;
}

/**
 * Sorted list of non-beta locales
 *
 * @deprecated use getFallbackLocale('nonBeta') instead
 */
export const fallbackLocale = localesFromJson.fallback;

export function getFallbackLocale(): NonBetaLocale {
	return fallbackLocale;
}

export type { Locale, BetaLocale, NonBetaLocale };
