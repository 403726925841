import type { SubEnvironment } from '@change-corgi/config/environments';
import type { Locale } from '@change-corgi/config/locales';

import __json from '../config/facebook.json';

type CountryData =
	| string
	| Readonly<{
			vanity: string;
			localesOverrides?: Readonly<Record<Locale, string>>;
	  }>;

type DataFromJson = Readonly<{
	appId: Readonly<Record<SubEnvironment, string>>;
	namespace: Readonly<Record<SubEnvironment, string>>;
	vanity: Readonly<Record<string, CountryData>>;
	defaultVanity: string;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
const data: DataFromJson = __json as any;

export function getFacebookVanity({ locale, countryCode }: { locale?: Locale; countryCode: string }): string {
	const countryConfig = data.vanity[countryCode];
	if (!countryConfig) {
		return data.defaultVanity;
	}
	if (typeof countryConfig === 'string') {
		return countryConfig;
	}
	if (!locale || !countryConfig.localesOverrides || !countryConfig.localesOverrides[locale]) {
		return countryConfig.vanity;
	}
	return countryConfig.localesOverrides[locale];
}

export const facebookAppIds = data.appId;
export const facebookNamespaces = data.namespace;
