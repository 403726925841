import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';
import { getLocales } from '@change-corgi/config/locales';

import { createFcmConfig } from '../shared/factory';

export const FCM_HOMEPAGE_SUPPORT_AWARENESS = createFcmConfig('homepage_support_awareness', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				title: { type: 'string' },
				content: { type: 'string' },
				locales: {
					type: 'array',
					shape: {
						type: 'enum',
						values: getLocales('nonBeta'),
					},
				},
			},
		}),
	),
	defaultValue: undefined,
});
