import {
	createJsonNormalizer,
	createLocaleBasedExperimentNormalizer,
	createShapeNormalizer,
} from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_GDPR_SIGN_OPT_IN_COPY = createFcmConfig('gdpr_sign_opt_in_copy', {
	normalizer: createJsonNormalizer(
		createLocaleBasedExperimentNormalizer(
			createShapeNormalizer({
				type: 'object',
				shape: {
					prompt: { type: 'string', optional: true },
					opt_in: { type: 'string', optional: true },
					opt_out: { type: 'string', optional: true },
					opt_out_warning: { type: 'string', optional: true },
					form_error: { type: 'string', optional: true },
				},
			}),
		),
	),
	defaultValue: undefined,
});
