import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_SAP_AI_IMAGE_PROMPT = createFcmConfig('sap_ai_image_prompt', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				systemPrompt: { type: 'string' },
				mainPrompt: {
					type: 'object',
					shape: {
						replacements: { type: 'array', shape: { type: 'string' }, optional: true },
						text: { type: 'string' },
					},
				},
				options: {
					type: 'object',
					optional: true,
					shape: {
						conversationsModel: { type: 'string', optional: true },
						imageModel: { type: 'string', optional: true },
						imageSize: { type: 'string', optional: true },
					},
				},
			},
		}),
	),
	defaultValue: {
		isEnabled: false,
		systemPrompt: '',
		mainPrompt: { text: '' },
		options: undefined,
	},
});
