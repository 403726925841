/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument */
import type { Normalizer } from '../types';

import {
	assertArray,
	assertBoolean,
	assertEnum,
	assertNumber,
	assertObject,
	assertOther,
	assertRecord,
	assertString,
} from './assert';
import type { ObjectShape, Shape } from './types';

function normalizeResultValue(value: any, { normalizer }: Shape) {
	return normalizer && value !== undefined ? (normalizer as Normalizer)(value) : value;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, max-lines-per-function, complexity
export function generateValueFromShape(valueShape: Shape, value: any, key?: string): any {
	switch (valueShape.type) {
		case 'string':
			assertString(value, !!valueShape.optional, key);
			return normalizeResultValue(value, valueShape);
		case 'enum':
			assertEnum(value, valueShape.values, !!valueShape.optional, key);
			return normalizeResultValue(value, valueShape);
		case 'number':
			assertNumber(value, !!valueShape.optional, key);
			return normalizeResultValue(value, valueShape);
		case 'boolean':
			assertBoolean(value, !!valueShape.optional, key);
			return normalizeResultValue(value, valueShape);
		case 'object':
			assertObject(value, !!valueShape.optional, key);
			return normalizeResultValue(
				value === undefined ? undefined : generateObjectFromShape(valueShape.shape, value, key),
				valueShape,
			);
		case 'array':
			assertArray(value, !!valueShape.optional, key);
			return normalizeResultValue(
				value === undefined
					? undefined
					: value.map((arrayValue: any, index: number) =>
							generateValueFromShape(valueShape.shape, arrayValue, key ? `${key}.${index}` : `${index}`),
						),
				valueShape,
			);
		case 'record':
			assertRecord(value, !!valueShape.optional, key);
			return normalizeResultValue(
				value === undefined
					? undefined
					: Object.entries(value).reduce<Partial<Record<string, unknown>>>((acc, [recordKey, recordValue]) => {
							if (valueShape.keys && !valueShape.keys.includes(recordKey)) return acc;
							// eslint-disable-next-line no-param-reassign
							acc[recordKey] = generateValueFromShape(
								valueShape.shape,
								recordValue,
								key ? `${key}.${recordKey}` : `${recordKey}`,
							);
							return acc;
						}, {}),
				valueShape,
			);
		case 'other':
			assertOther(value, !!valueShape.optional, key);
			return normalizeResultValue(value, valueShape);
		default:
			throw new Error('invalid shape');
	}
}

function generateObjectFromShape(shape: ObjectShape, value: any, parentKey?: string): any {
	assertObject(value, false);

	return Object.entries(shape).reduce<any>((acc, [key, valueShape]) => {
		// eslint-disable-next-line no-param-reassign
		acc[key] = generateValueFromShape(valueShape, value[key], parentKey ? `${parentKey}.${key}` : key);
		return acc;
	}, {});
}
