import type { Locale } from '@change-corgi/config/locales';

import __json from '../config/paypal.json';

type DataFromJson = Readonly<{
	locales: Readonly<Record<Locale, string>>;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
const data: DataFromJson = __json as any;

export function getPaypalLocale(locale: Locale): string {
	return data.locales[locale];
}
