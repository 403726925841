import __countries from '../config/countries.json';

type PartialCountryInfo = Readonly<{
	display: string;
	userSelectable: boolean;
	region?: 'foundation' | 'pbc' | 'northamerica';
}>;

type JsonCountryInfo = PartialCountryInfo &
	Readonly<{
		// eslint-disable-next-line @typescript-eslint/naming-convention
		__comment__?: string;
	}>;

type CountriesFromJson = Readonly<{
	countries: Readonly<Record<string, JsonCountryInfo>>;
	eu: readonly string[];
	gdpr: readonly string[];
	staffed: readonly string[];
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
const countriesFromJson: CountriesFromJson = __countries as any;

export type CountryInfo = PartialCountryInfo &
	Readonly<{
		code: string;
		eu: boolean;
		gdpr: boolean;
		staffed: boolean;
	}>;

const countryMap = Object.entries(countriesFromJson.countries).reduce<Partial<Record<string, CountryInfo>>>(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	(acc, [code, { __comment__, ...info }]) => ({
		...acc,
		[code]: {
			...info,
			code,
			eu: countriesFromJson.eu.includes(code),
			gdpr: countriesFromJson.gdpr.includes(code),
			staffed: countriesFromJson.staffed.includes(code),
		},
	}),
	{},
) as Readonly<Record<string, CountryInfo>>;

// eslint-disable-next-line no-nested-ternary
const sortFn = (l1: CountryInfo, l2: CountryInfo) => (l1.display < l2.display ? -1 : l1.display > l2.display ? 1 : 0);

/**
 * Sorted list of all country objects
 *
 * @deprecated use getCountriesInfo() instead
 */
export const countriesInfo: readonly CountryInfo[] = Object.values(countryMap).sort(sortFn);
/**
 * Sorted list of EU country objects
 *
 * @deprecated use getEuCountriesInfo() instead
 */
export const euCountriesInfo = countriesInfo.filter(({ eu }) => eu) as readonly CountryInfo[];
/**
 * Sorted list of GDPR country objects
 *
 * @deprecated use getGdprCountriesInfo() instead
 */
export const gdprCountriesInfo = countriesInfo.filter(({ gdpr }) => gdpr) as readonly CountryInfo[];
/**
 * Sorted list of user-selectable country objects
 *
 * @deprecated use getUserSelectableCountriesInfo() instead
 */
export const userSelectableCountriesInfo = countriesInfo.filter(
	({ userSelectable }) => userSelectable,
) as readonly CountryInfo[];
const staffedCountriesInfo = countriesInfo.filter(({ staffed }) => staffed) as readonly CountryInfo[];

/**
 * Sorted list of all country codes
 *
 * @deprecated use getCountries() instead
 */
export const countries = countriesInfo.map(({ code }) => code) as readonly string[];
/**
 * Sorted list of EU country codes
 *
 * @deprecated use getEuCountries() instead
 */
export const euCountries = euCountriesInfo.map(({ code }) => code) as readonly string[];
/**
 * Sorted list of GDPR country codes
 *
 * @deprecated use getGdprCountries() instead
 */
export const gdprCountries = gdprCountriesInfo.map(({ code }) => code) as readonly string[];
/**
 * Sorted list of user-selectable country objects
 *
 * @deprecated use getUserSelectableCountries() instead
 */
export const userSelectableCountries = userSelectableCountriesInfo.map(({ code }) => code) as readonly string[];
const staffedCountries = staffedCountriesInfo.map(({ code }) => code) as readonly string[];

/**
 * Sorted list of all country objects
 */
export function getCountriesInfo(): readonly CountryInfo[] {
	return countriesInfo;
}
/**
 * Sorted list of EU country objects
 */
export function getEuCountriesInfo(): readonly CountryInfo[] {
	return euCountriesInfo;
}
/**
 * Sorted list of GDPR country objects
 */
export function getGdprCountriesInfo(): readonly CountryInfo[] {
	return gdprCountriesInfo;
}
/**
 * Sorted list of user-selectable country objects
 */
export function getUserSelectableCountriesInfo(): readonly CountryInfo[] {
	return userSelectableCountriesInfo;
}
/**
 * Sorted list of staffed country objects
 */
export function getStaffedCountriesInfo(): readonly CountryInfo[] {
	return staffedCountriesInfo;
}

/**
 * Sorted list of all country codes
 */
export function getCountries(): readonly string[] {
	return countries;
}
/**
 * Sorted list of EU country codes
 */
export function getEuCountries(): readonly string[] {
	return euCountries;
}
/**
 * Sorted list of GDPR country codes
 */
export function getGdprCountries(): readonly string[] {
	return gdprCountries;
}
/**
 * Sorted list of user-selectable country objects
 */
export function getUserSelectableCountries(): readonly string[] {
	return userSelectableCountries;
}
/**
 * Sorted list of staffed country objects
 */
export function getStaffedCountries(): readonly string[] {
	return staffedCountries;
}

export function getCountryInfo(countryCode: string): CountryInfo | undefined {
	return countryMap[countryCode];
}
export function isEuCountry(countryCode: string): boolean {
	return euCountries.includes(countryCode);
}
export function isGdprCountry(countryCode: string): boolean {
	return gdprCountries.includes(countryCode);
}
export function isUserSelectableCountry(countryCode: string): boolean {
	return userSelectableCountries.includes(countryCode);
}
export function isStaffedCountry(countryCode: string): boolean {
	return staffedCountries.includes(countryCode);
}
