import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PACIFIC_PROMO_FREE_SAMPLE = createFcmConfig('pacific_promo_free_sample', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				impressions: { type: 'number' },
				amount: { type: 'number' },
				startDate: { type: 'string' },
				minSignatureCount: { type: 'number' },
			},
		}),
	),
	defaultValue: undefined,
});
