import type { Normalizer } from '@change-corgi/config/fcm/normalizers';
import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

type CopyKey = 'title' | 'subtitle' | 'savedPaymentWarningGeneric' | 'yesBtnTitle' | 'noBtnTitle';

type CopyKeys = Readonly<Record<CopyKey, string>>;

const shapeNormalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		title: { type: 'string', optional: true },
		subtitle: { type: 'string', optional: true },
		saved_payment_warning_generic: { type: 'string', optional: true },
		yes_btn_title: { type: 'string', optional: true },
		no_btn_title: { type: 'string', optional: true },
	},
});

const normalizeCopyKeys: Normalizer<Partial<CopyKeys>> = (value: unknown) => {
	const settings = shapeNormalizer(value);

	return {
		title: settings.title,
		subtitle: settings.subtitle,
		savedPaymentWarningGeneric: settings.saved_payment_warning_generic,
		yesBtnTitle: settings.yes_btn_title,
		noBtnTitle: settings.no_btn_title,
	};
};

export const FCM_MEMBERSHIP_PPMU_OFFSITE_COPY = createFcmConfig('membership_ppmu_offsite_copy', {
	normalizer: createJsonNormalizer(normalizeCopyKeys),
	defaultValue: {} as Partial<CopyKeys>,
});
