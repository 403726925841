import { MAPPING } from './mapping';
import type { CookiePrefs, Feature } from './types';

export function isTrackingAllowed(cookiePrefs: CookiePrefs, feature: Feature): boolean {
	const config = MAPPING[feature];
	if (typeof config === 'string') return cookiePrefs[config];
	if (config.test === 'ALL') return config.prefs.every((c) => cookiePrefs[c]);
	return config.prefs.some((c) => cookiePrefs[c]);
}

export type CookiePrefsFeature = Feature;
