import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PETITION_PROMOTE_PAGE_FOR_COUNTRY = createFcmConfig('petition_promote_page_for_country', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				traffic_percentage: { type: 'number' },
				promote_page_route: { type: 'string' },
				national_id_required: { type: 'boolean', optional: true },
				payment_methods: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							id: { type: 'string' },
							label: { type: 'string', optional: true },
							icon: { type: 'string' },
							flow: { type: 'string', optional: true },
							show_save_payment_method_checkbox: { type: 'boolean', optional: true },
							supports_recurring: { type: 'boolean', optional: true },
						},
					},
				},
				safe_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
				block_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
			},
		}),
	),
	defaultValue: undefined,
});
