import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_SHOW_SAVE_PAYMENT_CHECKBOX_CONFIG = createFcmConfig('show_save_payment_checkbox_config', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				stripe: { type: 'boolean', optional: true },
				paypal: { type: 'boolean', optional: true },
			},
		}),
	),
	defaultValue: {
		stripe: true,
		paypal: true,
	},
});
