import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const PAYMENT_METHOD_TYPES = ['credit_card', 'sepa_debit', 'google_pay', 'apple_pay', 'paypal', 'upi'];

const normalizer = createShapeNormalizer({
	type: 'record',
	keys: PAYMENT_METHOD_TYPES,
	shape: { type: 'number' },
});

/**
 * expected format:
 * - json: {"sepa_debit": 20000, "payment_method_type2": 10000 }
 * - supported payment_method_type enums - `credit_card`, `sepa_debit`, `google_pay`, `apple_pay`, `paypal`, `upi`
 */
export const FCM_PAYMENTS_REFUNDS_MAX_AMOUNTS = createFcmConfig('payments_refunds_max_amounts', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {},
});
