import type { Normalizer } from '@change-corgi/config/fcm/normalizers';
import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizeNumberRecordShape = createShapeNormalizer({
	type: 'record',
	shape: { type: 'number' },
});

type SsrCacheLifetimeSecondsOverride = Readonly<Record<string, number>>;

export const normalizeSsrCacheLifetimeSecondsOverride: Normalizer<SsrCacheLifetimeSecondsOverride> = (
	value: unknown,
) => {
	const objectValue = normalizeNumberRecordShape(value);

	return Object.entries(objectValue)
		.filter(([_name, val]) => val > 0)
		.reduce(
			(acc, [name, val]) => ({
				...acc,
				[name]: val,
			}),
			{},
		);
};

export const FCM_CORGI_SSR_CACHE_LIFETIME_SECONDS_OVERRIDE = createFcmConfig(
	'corgi_ssr_cache_lifetime_seconds_override',
	{
		normalizer: createJsonNormalizer(normalizeSsrCacheLifetimeSecondsOverride),
		defaultValue: {} as SsrCacheLifetimeSecondsOverride,
	},
);
