import __subscriptionCountries from '../config/subscriptionCountries.json';

type SubscriptionCountries = Readonly<{
	interval: 'monthly';
	amounts: readonly [number, number, number, number];
	defaultAmount: number;
	maxCustomAmount?: number;
	thirdPartySubscriptionService?: string;
	disableFeedbackForm?: boolean;
	staffCount?: number;
	subscriptionCancellationSurveyId?: string;
}>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const countrySettingsJson: Readonly<
	Record<string, Omit<SubscriptionCountries, 'defaultAmount'>>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
> = __subscriptionCountries as any;

export const subscriptions = {
	countries: Object.entries(countrySettingsJson).reduce<Readonly<Record<string, SubscriptionCountries>>>(
		(acc, [code, settings]) => ({
			...acc,
			[code]: { ...settings, defaultAmount: settings.amounts[1] },
		}),
		{},
	),
};
