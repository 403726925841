import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const normalizeGatewayRouterStripeCreatePaymentMethodConfig = createShapeNormalizer({
	type: 'object',
	shape: {
		tokenization_account: {
			type: 'object',
			shape: {
				id: { type: 'string' },
			},
		},
	},
});

export const FCM_GATEWAY_ROUTER_STRIPE_CREATE_PAYMENT_METHOD_CONFIG = createFcmConfig(
	'gateway_router_stripe_create_payment_method_config',
	{
		normalizer: createJsonNormalizer(normalizeGatewayRouterStripeCreatePaymentMethodConfig),
		defaultValue: undefined,
	},
);
