import type { Normalizer } from '@change-corgi/config/fcm/normalizers';
import { createJsonNormalizer, createShapeNormalizer, normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizeBooleanRecordShape = createShapeNormalizer({
	type: 'record',
	shape: { type: 'boolean' },
});

type SsrEnabled = Readonly<{
	default: boolean;
	overrides: Readonly<Record<string, boolean>>;
}>;

/**
 * expected format:
 * - boolean
 * - json: { "default": true, "route-id": false, "other-route-id": false }
 */
export const normalizeSsrEnabled: Normalizer<SsrEnabled> = (value: unknown) => {
	try {
		return {
			default: normalizeBoolean(value),
			overrides: {},
		};
	} catch (e) {
		const objectValue = normalizeBooleanRecordShape(value);
		return {
			default: !!objectValue.default,
			overrides: Object.entries(objectValue)
				.filter(([name]) => name !== 'default')
				.reduce(
					(acc, [name, val]) => ({
						...acc,
						[name]: val,
					}),
					{},
				),
		};
	}
};

export const FCM_CORGI_SSR_CACHE = createFcmConfig('corgi_ssr_cache', {
	normalizer: createJsonNormalizer(normalizeSsrEnabled),
	defaultValue: { default: false, overrides: {} } as SsrEnabled,
});
