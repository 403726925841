import type { NonBetaLocale } from '@change-corgi/config/locales';

import __routesFromYaml from '../config/routes.yml';

type MandatoryRoute =
	| 'about'
	| 'advertise'
	| 'careers'
	| 'guides'
	| 'impact'
	| 'promoted_petitions'
	| 'team'
	| 'create_petition'
	| 'share_petition'
	| 'build_momentum'
	| 'reach_media'
	| 'engage_decision_maker'
	| 'declare_victory'
	| 'how_petition_works'
	| 'start_online_petition'
	| 'promote_your_petition'
	| 'talk_to_decision_maker'
	| 'advanced_tips'
	| 'grow_support_base'
	| 'get_story_to_media'
	| 'build_media_conversation'
	| 'deliver_in_person'
	| 'organize_call_in_day';

type OptionalRoute = 'connect';

export type Routes = Record<MandatoryRoute, string> & Partial<Record<OptionalRoute, string>>;

type RoutesFromYaml = Readonly<
	Record<
		NonBetaLocale,
		Readonly<{
			fe: Readonly<{
				routes: Readonly<Routes>;
			}>;
		}>
	>
>;

const routesFromYaml = __routesFromYaml as unknown as RoutesFromYaml;

type RoutesLocaleMap = Readonly<Record<NonBetaLocale, Routes>>;

function removeNullValues(routes: Routes) {
	if (routes.connect === null) {
		// eslint-disable-next-line no-param-reassign
		delete routes.connect;
	}
	return routes;
}

export const routes: RoutesLocaleMap = Object.entries(routesFromYaml).reduce<Partial<RoutesLocaleMap>>(
	(acc, [locale, data]) => ({
		...acc,
		[locale]: removeNullValues(data.fe.routes),
	}),
	{},
) as RoutesLocaleMap;
